import React, { useState } from 'react';
import classnames from 'classnames';
import { Box } from '@mui/material';
import AddonCard from '../../../../components/AddonCard/AddonCard';
import AddonCardSkeleton from '../../../../components/AddonCard/AddonCard.Skeleton';
import {
	createURL,
	getAddonCatalogLink,
	getAddonsQueryVariables,
	getCurrentWatchingContentId,
	Queries,
	useMembershipContext,
	useUserContext
} from 'common';
import { HorizontalList } from 'components';

import useStyles from './MediathekAddons.styles';

type Props = {
	className?: string;
};

const MediathekAddons = (props: Props) => {
	const { className } = props;
	const userCtx = useUserContext();
	const { user } = userCtx;
	const { userMembership, isMember } = useMembershipContext();
	const { name: companyName } = user?.company || {};
	const [offset, setOffset] = useState(0);
	const classes = useStyles();
	const { data, loading: loading } = Queries.useGetAddOnContentsQuery({
		variables: getAddonsQueryVariables(),
		skip: !companyName
	});

	const addonContents = data?.addOnContents.data || [];

	const hasRegularMembership = isMember;
	const hasGroupMembership = Boolean(userMembership?.addedToGroupMembershipDateTime);

	if (!user || (!hasRegularMembership && !hasGroupMembership) || !companyName) {
		return null;
	}

	const pageSize = 3;
	const visibleList = addonContents.slice(offset, offset + pageSize);

	return (
		<Box className={classnames(classes.container, className)}>
			<Box>
				<HorizontalList
					items={visibleList}
					renderItem={(addonContent) => {
						const progressLink = createURL(
							`/mediathek/addons/${addonContent.contentId}`,
							'catalog',
							true
						).url;
						const contentId = getCurrentWatchingContentId(addonContent.schedule ?? []);
						const currentSchedule = addonContent.schedule?.find(
							(scheduleItem) => scheduleItem.contentId === contentId
						);
						const courseLink = getAddonCatalogLink(
							addonContent.contentId,
							currentSchedule?.contentId
						);
						return (
							<AddonCard
								fullContent={{
									...addonContent,
									videos: addonContent.schedule.map((schedule) => ({
										contentId: schedule.contentId,
										contentType: schedule.contentType,
										contentProgress: schedule.contentProgress,
										endDateTime: schedule.endDateTime,
										id: schedule.id,
										title: schedule.title,
										product: schedule.product,
										replacementDate: schedule.replacementDate,
										source: schedule.source
									}))
								}}
								courseLink={courseLink}
								progressLink={progressLink}
								className={classes.addonCard}
								isCompact
							/>
						);
					}}
					numberOfItemsInRow={pageSize}
					totalCount={addonContents.length}
					offset={offset}
					onOffsetChange={setOffset}
					classes={{
						horizontalListContainer: classes.horizontalListContainer,
						item: classes.addonCardListItem
					}}
					renderSkeletonItem={() => (
						<AddonCardSkeleton className={classes.addonCard} isCompact />
					)}
					isSkeletonVisible={loading}
				/>
			</Box>
		</Box>
	);
};

export default MediathekAddons;
