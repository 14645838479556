import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Container, DialogContent } from '@mui/material';
import { Button, Dialog, Icon, Typography } from 'components';
import { useIntl } from 'react-intl';
import {
	BOOKING_URLS,
	getCountryCode,
	isFeatureEnabled,
	useIsDesktop,
	useMembershipContext,
	useUserContext
} from 'common';
import Player from '../../../components/Player/Player';

/**
 * A dictionary with the Membership Benefits Ad strings, displayed at the Home page.
 */
const MembershipBenefitsData = [
	{
		amountStringId: 'catalog.home.group-membership-banner.benefit-1.title',
		benefitStringId: 'catalog.home.group-membership-banner.benefit-1.subtitle'
	},
	{
		amountStringId: 'catalog.home.group-membership-banner.benefit-2.title',
		benefitStringId: 'catalog.home.group-membership-banner.benefit-2.subtitle'
	},
	{
		amountStringId: 'catalog.home.group-membership-banner.benefit-3.title',
		benefitStringId: 'catalog.home.group-membership-banner.benefit-3.subtitle'
	},
	{
		amountStringId: 'catalog.home.group-membership-banner.benefit-4.title',
		benefitStringId: 'catalog.home.group-membership-banner.benefit-4.subtitle'
	}
];

const useStyles = makeStyles((theme) => ({
	fullSizeContainer: {
		position: 'relative',
		margin: theme.spacing(9, 0),
		overflow: 'hidden',
		padding: theme.spacing(3, 5),

		[theme.breakpoints.down('md')]: {
			margin: theme.spacing(5, 0),
			padding: theme.spacing(3)
		},
		...theme.border({ color: theme.palette.body.light, radius: 8 })
	},
	clipPath: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		background: 'linear-gradient(to bottom right, #EBF8FD, white)',
		clipPath:
			'polygon(80% 0, 76% 20%, 70% 39%, 63% 55%, 55% 77%, 46% 100%, 0 100%, 0% 43%, 0 0)',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		overflow: 'hidden',

		[theme.breakpoints.down('md')]: {
			clipPath: 'none',
			background: 'linear-gradient(180deg, #EFFAFE, white)'
		}
	},
	membershipBenefitsBannerContainer: {
		position: 'relative',
		marginTop: theme.spacing(9),
		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(0)
		}
	},
	sectionTitle: {
		fontWeight: 700,
		fontSize: 36,
		lineHeight: '54px',
		color: theme.palette.secondary.main,
		marginBottom: theme.spacing(5),

		[theme.breakpoints.down('md')]: {
			fontSize: 28,
			lineHeight: '42px'
		}
	},
	benefitsContainer: {
		display: 'flex',
		gap: theme.spacing(1),
		justifyContent: 'space-between',

		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(4)
		}
	},
	checkIcon: {
		transform: 'scale(1.5)',
		color: theme.palette.secondary.main,
		height: 32,
		width: 32,
		paddingTop: theme.spacing(0.5)
	},
	benefitItem: {
		display: 'flex',
		gap: theme.spacing(2),
		alignItems: 'start',
		height: 60,
		color: theme.palette.primary.main,
		boxSizing: 'content-box',

		[theme.breakpoints.down('md')]: {
			height: 'unset',
			textAlign: 'center',
			alignItems: 'center'
		}
	},
	benefitText: {
		[theme.breakpoints.down('md')]: {
			textAlign: 'left'
		}
	},
	benefitItemAmount: {
		fontSize: 24,
		color: '#59748D',
		fontWeight: 500,
		lineHeight: 'normal'
	},
	callToActionBtn: {
		width: '100%',
		fontWeight: 'normal',
		height: 'unset !important',
		padding: '7px 24px !important',
		borderRadius: '16px !important'
	},
	callToActionBtnContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		alignItems: 'center',
		width: '20%',
		[theme.breakpoints.down('md')]: {
			alignItems: 'center',
			width: '100%',

			'& a': {
				width: 'fit-content'
			}
		}
	},
	endContent: {
		marginTop: theme.spacing(3),
		display: 'flex',
		justifyContent: 'end',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center'
		}
	},
	membershipTeaser: {
		display: 'inline-flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: theme.spacing(1.5, 2, 1.5, 1.5),
		borderRadius: 6,
		transition: 'background-color 0.1s',
		'&:hover': {
			backgroundColor: theme.palette.body[200]
		}
	},
	membershipTeaserTitle: {
		margin: theme.spacing(0, 0, 0, 1),
		fontFamily: 'Poppins',
		color: theme.palette.primary.main,
		...theme.typography.body1
	},
	playerContainer: {
		padding: theme.spacing(3, 0, 0, 0),
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(3)
		}
	}
}));

const mediathekVideoByLocale: Record<Locale, string> = {
	'de-CH': 'https://vimeo.com/1008016984?share=copy',
	'de-DE': 'https://vimeo.com/1008016331?share=copy',
	'fr-CH': 'https://vimeo.com/1008016363?share=copy',
	'de-AT': 'https://vimeo.com/1008016313?share=copy',
	'en-GB': ''
};

export const MembershipBenefitsBannerBlue = () => {
	const classes = useStyles();
	const { locale } = useIntl();
	const { isLoading: userContextLoading, isLoggedIn } = useUserContext();
	const { isMember } = useMembershipContext();
	const isMembershipEnabled = isFeatureEnabled('memberships', getCountryCode(locale));
	const membershipVideo = mediathekVideoByLocale[locale] || '';
	const [isOpenMediathekVideo, setIsOpenMediathekVideo] = useState(false);
	const handlePlayMembersihpClick = () => setIsOpenMediathekVideo(true);
	const handleCloseMembershipVideo = () => setIsOpenMediathekVideo(false);
	const isDesktop = useIsDesktop();

	const shouldHideMembershipBanner =
		userContextLoading === true || (userContextLoading === false && isLoggedIn === true);
	if (shouldHideMembershipBanner) {
		return null;
	}
	const shouldShowPlayerDialog = !isMember && isMembershipEnabled && membershipVideo;

	return (
		<Container maxWidth="xl">
			<div className={classes.fullSizeContainer}>
				<div className={classes.clipPath} />
				<div className={classes.membershipBenefitsBannerContainer}>
					{shouldShowPlayerDialog && (
						<Dialog
							open={isOpenMediathekVideo}
							onClose={handleCloseMembershipVideo}
							maxWidth={isDesktop ? 'lg' : 'md'}
							fullWidth
						>
							<DialogContent>
								<Box className={classes.playerContainer}>
									<Player url={membershipVideo} />
								</Box>
							</DialogContent>
						</Dialog>
					)}
					<Typography
						className={classes.sectionTitle}
						variant="h3"
						localeId="catalog.home.group-membership-banner.membership-benefits"
					/>
					<div className={classes.benefitsContainer}>
						{MembershipBenefitsData.map((benefit) => (
							<div className={classes.benefitItem} key={benefit.benefitStringId}>
								<Icon icon="checkmarkLight" className={classes.checkIcon} />
								<div className={classes.benefitText}>
									<Typography
										className={classes.benefitItemAmount}
										localeId={benefit.amountStringId}
										component="span"
									/>
									<Typography localeId={benefit.benefitStringId} />
								</div>
							</div>
						))}
						<div className={classes.callToActionBtnContainer}>
							<Button
								href={BOOKING_URLS.memberships}
								className={classes.callToActionBtn}
								color="secondary"
								uppercase={false}
								localeId="catalog.home.group-membership-banner.learn-more-about-membership"
							/>
							<Button
								href="/gruppenmitgliedschaft"
								className={classes.callToActionBtn}
								variant="outlined"
								color="secondary"
								uppercase={false}
								localeId="catalog.home.group-membership-banner.learn-more-about-group-membership"
							/>
						</div>
					</div>
					{shouldShowPlayerDialog && (
						<div className={classes.endContent}>
							<Box
								className={classes.membershipTeaser}
								onClick={handlePlayMembersihpClick}
							>
								<img src="https://storage.googleapis.com/bk-fms-eu/public/static/icon-play-button-circled.svg" />
								<Typography
									className={classes.membershipTeaserTitle}
									localeId="catalog.home.group-membership-banner.more-about-membership"
								/>
							</Box>
						</div>
					)}
				</div>
			</div>
		</Container>
	);
};
